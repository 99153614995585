<template>
  <div class="search___url">
    <b-container class="wrapper" v-if="!showResult">
      <b-row>
        <b-col>
          <h2 class="mb-4">Advanced Phone Search</h2>
          <p>
            Please enter all the information you have about the person you've
            searching for. <br />
            At least one field is required Email, Phone , username, name (First
            + Last)
          </p>
          <p>
            Be careful not to use this with too common names since it can be
            challenging to identify the correct profile when there are too many
            results.
          </p>
          <p>
            Regardless of the number of results, 75 credits will be charged (If
            we find 10 persons, we are still charging 75 credits , and not 750
            credits )
          </p>
        </b-col>
      </b-row>
      <!-- row -->
      <b-row>
        <b-col lg="12" class="main__wrap">
          <div>
            <b-tabs content-class="mt-3">
              <!-- tab 1 -->
              <b-tab title="Search by name" active>
                <form @submit.prevent="checkAPIBody('name')">
                  <div class="ui_content">
                    <div class="title">
                      <h4>Search by name</h4>
                    </div>
                    <div clas="search__box">
                      <b-row class="mb-3">
                        <b-col class="col-12 col-lg-4 col-md-12 col">
                          <div class="firstname form__input">
                            <div class="global__icon">
                              <img
                                src="@/assets/images/icons/user.svg"
                                alt=""
                              />
                            </div>
                            <b-form-input
                              v-model="formPayload.firstName"
                              placeholder="First Name"
                            ></b-form-input>
                          </div>
                        </b-col>

                        <b-col class="col-12 col-lg-4 col-md-12 col">
                          <div class="middlename form__input">
                            <div class="global__icon">
                              <img
                                src="@/assets/images/icons/user.svg"
                                alt=""
                              />
                            </div>
                            <b-form-input
                              v-model="formPayload.middleName"
                              placeholder="Middle Name"
                            ></b-form-input>
                          </div>
                        </b-col>

                        <b-col class="col-12 col-lg-4 col-md-12 col">
                          <div class="lastname form__input">
                            <div class="global__icon">
                              <img
                                src="@/assets/images/icons/user.svg"
                                alt=""
                              />
                            </div>
                            <b-form-input
                              v-model="formPayload.lastName"
                              placeholder="Last Name"
                            ></b-form-input>
                          </div>
                        </b-col>
                      </b-row>

                      <b-row class="mb-4">
                        <b-col class="col-12 col-lg-4 col-md-12 col">
                          <div class="email-text">
                            <div class="country-list-box">
                              <svg
                                class="drop-ico"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M10 2C13.866 2 17 5.18858 17 9.12189C17 11.7112 14.9045 14.4482 10.8016 17.4281L10.445 17.6841L10 18L9.55495 17.6841C5.21699 14.6047 3 11.7852 3 9.12189C3 5.18858 6.13401 2 10 2ZM10 4C7.24638 4 5 6.28549 5 9.12189C5 10.7706 6.48662 12.8514 9.68512 15.297L10 15.5348L10.3149 15.297C13.5134 12.8514 15 10.7706 15 9.12189C15 6.28549 12.7536 4 10 4ZM10 7C11.1046 7 12 7.89543 12 9C12 10.1046 11.1046 11 10 11C8.89543 11 8 10.1046 8 9C8 7.89543 8.89543 7 10 7Z"
                                  fill="#3A394C"
                                />
                              </svg>
                              <v-select
                                v-model="formPayload.country"
                                label="name"
                                placeholder="Country Code"
                                :components="{ Deselect }"
                                :options="countryList"
                                :reduce="(option) => option.alpha2"
                              ></v-select>
                            </div>
                          </div>
                        </b-col>

                        <b-col class="col-12 col-lg-4 col-md-12 col">
                          <div class="state form__input">
                            <div class="global__icon">
                              <img
                                src="@/assets/images/icons/searchLocation.svg"
                                alt=""
                              />
                            </div>
                            <b-form-input
                              v-model="formPayload.state"
                              placeholder="State Code (US Only)"
                            ></b-form-input>
                          </div>
                        </b-col>

                        <b-col class="col-12 col-lg-4 col-md-12 col">
                          <div class="city form__input">
                            <div class="global__icon">
                              <img
                                src="@/assets/images/icons/searchLocation.svg"
                                alt=""
                              />
                            </div>
                            <b-form-input
                              v-model="formPayload.city"
                              placeholder="City (US Only)"
                            ></b-form-input>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- <b-col class="col-12 col-lg-4 col-md-12 col">
                        <label class="switch-new">
                          <input
                            type="checkbox"
                            v-model="checked"
                            @click="toogleClick()"
                          />
                          <span> Exclude other countries </span></label
                        >
                      </b-col> -->
                        <b-col
                          class="search-btn btn__conatiner col-12 col-lg-12"
                        >
                          <b-button
                            v-bind:class="{
                              'btn-search-grey': getGreyClass('name'),
                            }"
                            :disabled="getGreyClass('name')"
                            variant="primary"
                            type="submit"
                            >Search</b-button
                          >
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </form>
              </b-tab>
              <!-- tab 2 -->
              <b-tab title="Search by social URL">
                <form @submit.prevent="checkAPIBody('username')">
                  <div class="ui_content">
                    <div class="title">
                      <h4>Search by social URL</h4>
                    </div>
                    <div class="search__box">
                      <b-input-group>
                        <div class="global__icon">
                          <img src="@/assets/images/icons/globe.svg" alt="" />
                        </div>

                        <b-form-input
                          type="search"
                          placeholder="LinkedIn, Facebook or Twitter profile URL"
                          v-model="formPayload.username"
                          name="username"
                        ></b-form-input>
                      </b-input-group>
                      <div class="search-btn btn__conatiner">
                        <b-button
                          v-bind:class="{
                            'btn-search-grey': getGreyClass('username'),
                          }"
                          :disabled="getGreyClass('username')"
                          variant="primary"
                          type="submit"
                          >Search</b-button
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </b-tab>
              <!-- tab 3 -->
              <b-tab title="Search by email">
                <form @submit.prevent="checkAPIBody('email')">
                  <div class="ui_content">
                    <div class="title">
                      <h4>Search by email</h4>
                    </div>
                    <div clas="search__box">
                      <b-input-group>
                        <div class="global__icon">
                          <img
                            src="@/assets/images/icons/emailIcon.svg"
                            alt=""
                          />
                        </div>

                        <b-form-input
                          v-model="formPayload.email"
                          type="search"
                          placeholder="Email"
                        ></b-form-input>
                      </b-input-group>
                      <div class="search-btn btn__conatiner">
                        <b-button
                          v-bind:class="{
                            'btn-search-grey': getGreyClass('email'),
                          }"
                          :disabled="getGreyClass('email')"
                          variant="primary"
                          type="submit"
                          >Search</b-button
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </b-tab>
              <!-- tab 4 -->
              <!-- <b-tab title="Search by phone">
                <form @submit.prevent="checkAPIBody('phone')">
                  <div class="ui_content">
                    <div class="title">
                      <h4>Search by phone</h4>
                    </div>
                    <div clas="search__box">
                      <b-input-group>
                        <div class="global__icon">
                          <img
                            src="@/assets/images/icons/searchPhone.svg"
                            alt=""
                          />
                        </div>

                        <b-form-input
                          v-model="formPayload.phone"
                          type="search"
                          placeholder="Phone"
                        ></b-form-input>
                      </b-input-group>
                      <div class="search-btn btn__conatiner">
                        <b-button
                          v-bind:class="{
                            'btn-search-grey': getGreyClass('phone'),
                          }"
                          :disabled="getGreyClass('phone')"
                          variant="primary"
                          type="submit"
                          >Search</b-button
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </b-tab> -->
            </b-tabs>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="showResult" class="bv-example-row mb-3">
      <b-row class="new-result">
        <b-col cols="12">
          <h2>Results</h2>
        </b-col>
        <b-col cols="6" class="d-flex align-items-center">
          <p class="m-0 mr-3">
            Show only result with mobile phone number
          </p>

          <div class="result-ne d-flex justify-content-between">
            <label class="switch">
              <b-form-checkbox
                name="check-button"
                v-model="withPhone"
                @change="onChangeFilter()"
                switch
                size="lg"
              ></b-form-checkbox>
            </label>
          </div>
        </b-col>
        <b-col cols="6">
          <button class="btn btn-primary float-right" @click="close()">
            Back
          </button>
        </b-col>
      </b-row>
      <b-row
        class="new-result"
        v-if="
          cerebroSearchRes &&
            cerebroSearchRes.length > 0 &&
            cerebroSearchRes.some((dt) => dt.phones.length > 0)
        "
      >
        <b-col cols="6" class="d-flex align-items-center"
          ><button
            class="btn btn-primary float-right"
            @click="copyAllContacts()"
          >
            Copy All Numbers
          </button>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="showResult">
      <b-row v-if="cerebroSearchRes && cerebroSearchRes.length > 0">
        <b-col
          class="col-12 col-md-12 col-lg-6 col-xl-4"
          v-for="(obj, index) in cerebroSearchRes"
          :key="index"
        >
          <div class="start-result shadow-sm  mb-4 bg-body rounded">
            <div
              class="username-withimage"
              :class="{ error_message: obj.errorMessage != null }"
            >
              <div
                class="result-name d-flex justify-content-between align-items-baseline"
              >
                <div class="name-btn">
                  <h4
                    v-if="obj.names && obj.names.length > 0"
                    class="text-dark d-flex align-items-baseline"
                  >
                    {{ obj.names[0].display }}
                    <div v-if="obj.names && obj.names.length > 1">
                      <b-dropdown
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        class="text-dark new"
                      >
                        <template #button-content>
                          <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.3984 4.55315C10.2134 4.6657 9.33174 4.91582 8.33437 5.42233C7.15253 6.02575 6.09264 6.97622 5.31725 8.12679C4.99209 8.61453 4.59189 9.43681 4.39805 10.0183C4.24485 10.4873 4.11978 11.022 4.04475 11.5504C3.99159 11.9193 3.99159 13.1512 4.04475 13.5201C4.17606 14.4424 4.41993 15.2491 4.80137 16.0213C5.22971 16.8968 5.65179 17.4845 6.34589 18.1818C7.03685 18.8696 7.63402 19.2979 8.47506 19.7106C9.06286 19.9983 9.44117 20.139 10.0383 20.2891C11.6016 20.683 13.1711 20.608 14.6969 20.0671C15.1628 19.9014 15.9694 19.498 16.3852 19.2198C17.3201 18.5976 18.1767 17.719 18.7895 16.7561C19.0147 16.4028 19.3773 15.6524 19.5243 15.2366C20.259 13.1668 20.1308 10.9469 19.1647 9.00535C18.777 8.22371 18.3424 7.60778 17.7328 6.97935C17.0574 6.279 16.3946 5.79751 15.5098 5.35979C14.5843 4.90332 13.7214 4.65632 12.6959 4.56252C12.377 4.53439 11.6548 4.52813 11.3984 4.55315ZM12.3489 8.47071C12.4114 8.51448 12.5021 8.60515 12.549 8.67081L12.6334 8.79274L12.649 10.3279L12.6646 11.863L14.1998 11.8786L15.7349 11.8943L15.8568 11.9787C15.9225 12.0256 16.0132 12.1163 16.0569 12.1819C16.1226 12.2788 16.1351 12.332 16.1351 12.5352C16.1351 12.7384 16.1226 12.7916 16.0569 12.8885C16.0132 12.9542 15.9225 13.0448 15.8568 13.0917L15.7349 13.1762L14.1998 13.1918L12.6646 13.2074L12.649 14.7426L12.6334 16.2777L12.549 16.3996C12.5021 16.4653 12.4114 16.556 12.3457 16.5997C12.2488 16.6654 12.1957 16.6779 11.9924 16.6779C11.7892 16.6779 11.7361 16.6654 11.6391 16.5997C11.5735 16.556 11.4828 16.4653 11.4359 16.3996L11.3515 16.2777L11.3359 14.7426L11.3202 13.2074L9.78509 13.1918L8.24995 13.1762L8.12802 13.0917C7.93417 12.9542 7.85288 12.8104 7.84038 12.5696C7.82787 12.3132 7.91229 12.1288 8.11239 11.9881L8.24683 11.8943L9.78196 11.8786L11.3202 11.863L11.3359 10.3248L11.3515 8.78962L11.4453 8.65517C11.586 8.45508 11.7704 8.37066 12.0268 8.38317C12.1832 8.39254 12.2613 8.41443 12.3489 8.47071Z"
                              fill="#2589FF"
                            />
                          </svg>
                        </template>
                        <div class="icon-drop">
                          <b-dropdown-item
                            href="javascript:void(0)"
                            class="text-dark"
                            v-bind:class="j == 0 ? 'active' : ''"
                            v-for="(nameObj, j) in obj.names"
                            :key="j"
                            >{{ nameObj.display }}
                          </b-dropdown-item>
                        </div>
                      </b-dropdown>
                    </div>
                  </h4>
                  <button @click="advancePhoneSearchImg(obj.phones, index)">
                    view picture
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.97703 3.21999C10.2699 2.92667 10.7448 2.92667 11.0377 3.21999L15.2803 7.46889C15.421 7.60975 15.5 7.80079 15.5 8C15.5 8.19921 15.421 8.39025 15.2803 8.53111L11.0377 12.78C10.7448 13.0733 10.2699 13.0733 9.97703 12.78C9.68414 12.4867 9.68414 12.0111 9.97703 11.7178L12.9393 8.75111L1.75 8.75111C1.33579 8.75111 1 8.41482 1 8C1 7.58518 1.33579 7.24889 1.75 7.24889L12.9393 7.24889L9.97703 4.28222C9.68414 3.98889 9.68414 3.51332 9.97703 3.21999Z"
                        fill="#023175"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  class="indeed-icon"
                  v-if="obj.urls && obj.urls.length > 0"
                  @click="openLinkInUrl(obj.urls[0].url)"
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.8571 4.53748H5.13929C4.51071 4.53748 4 5.05533 4 5.69105V19.3839C4 20.0196 4.51071 20.5375 5.13929 20.5375H18.8571C19.4857 20.5375 20 20.0196 20 19.3839V5.69105C20 5.05533 19.4857 4.53748 18.8571 4.53748ZM8.83571 18.2518H6.46429V10.616H8.83929V18.2518H8.83571ZM7.65 9.57319C6.88929 9.57319 6.275 8.95533 6.275 8.19819C6.275 7.44105 6.88929 6.82319 7.65 6.82319C8.40714 6.82319 9.025 7.44105 9.025 8.19819C9.025 8.9589 8.41071 9.57319 7.65 9.57319V9.57319ZM17.725 18.2518H15.3536V14.5375C15.3536 13.6518 15.3357 12.5125 14.1214 12.5125C12.8857 12.5125 12.6964 13.4768 12.6964 14.4732V18.2518H10.325V10.616H12.6V11.6589H12.6321C12.95 11.0589 13.725 10.4268 14.8786 10.4268C17.2786 10.4268 17.725 12.0089 17.725 14.066V18.2518V18.2518Z"
                      fill="#2589FF"
                    />
                  </svg>
                </div>
              </div>
              <!-- <div>
              <span v-if="index != imageIndex " >
                <img src="@/assets/images/face-scan.png" alt=""  class="wts-img"  @click="advancePhoneSearchImg(obj.phones, index)"/>
              </span>
              <span v-if="imageIndex != null && index == imageIndex && displayError == null" >
                <img :src=imageUrl alt="" class="wts-img" />
              </span>
              <span v-if="displayError != null && index === imageIndex  ">
              <p>{{ displayError }} </p>
           

              </span>
            </div> -->

              <div>
                <!-- Condition 1: Show a static image if the current index is not the same as the imageIndex -->
                <span v-if="obj.imageUrl == null && obj.errorMessage == null">
                  <img
                    src="@/assets/images/face-scan.png"
                    alt=""
                    class="wts-img"
                  />
                </span>

                <!-- Condition 2: Show the actual image if imageIndex is not null and the current index is the same as imageIndex, and no displayError -->
                <span v-else-if="obj.imageUrl != null">
                  <img :src="obj.imageUrl" alt="" class="wts-img" />
                </span>

                <!-- Condition 3: Show an error message if displayError is not null and the current index is the same as imageIndex -->
                <span
                  class="error-image-msg"
                  v-else-if="obj.errorMessage != null"
                >
                  <p>{{ obj.errorMessage }}</p>
                </span>
              </div>
            </div>
            <!-- <div v-if="showWtsImage == true  "  >
              <img :src="imageUrl" alt="">
            </div> -->

            <div
              class="result-data mt-1 mb-1"
              v-if="obj.jobs && obj.jobs.length > 0"
            >
              <h6 class="nomargin" v-if="obj.jobs[0].organization">
                {{ obj.jobs[0].organization }}
              </h6>
              <span v-if="obj.jobs[0].title">{{ obj.jobs[0].title }}</span>
            </div>

            <div class="address-res">
              <div class="d-flex align-items-baseline">
                <div>
                  <h6 v-if="obj.addresses && obj.addresses.length > 0">
                    {{ obj.addresses[0].display }}
                  </h6>
                </div>

                <div v-if="obj.addresses && obj.addresses.length > 1">
                  <b-dropdown
                    size="lg"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.3984 4.55315C10.2134 4.6657 9.33174 4.91582 8.33437 5.42233C7.15253 6.02575 6.09264 6.97622 5.31725 8.12679C4.99209 8.61453 4.59189 9.43681 4.39805 10.0183C4.24485 10.4873 4.11978 11.022 4.04475 11.5504C3.99159 11.9193 3.99159 13.1512 4.04475 13.5201C4.17606 14.4424 4.41993 15.2491 4.80137 16.0213C5.22971 16.8968 5.65179 17.4845 6.34589 18.1818C7.03685 18.8696 7.63402 19.2979 8.47506 19.7106C9.06286 19.9983 9.44117 20.139 10.0383 20.2891C11.6016 20.683 13.1711 20.608 14.6969 20.0671C15.1628 19.9014 15.9694 19.498 16.3852 19.2198C17.3201 18.5976 18.1767 17.719 18.7895 16.7561C19.0147 16.4028 19.3773 15.6524 19.5243 15.2366C20.259 13.1668 20.1308 10.9469 19.1647 9.00535C18.777 8.22371 18.3424 7.60778 17.7328 6.97935C17.0574 6.279 16.3946 5.79751 15.5098 5.35979C14.5843 4.90332 13.7214 4.65632 12.6959 4.56252C12.377 4.53439 11.6548 4.52813 11.3984 4.55315ZM12.3489 8.47071C12.4114 8.51448 12.5021 8.60515 12.549 8.67081L12.6334 8.79274L12.649 10.3279L12.6646 11.863L14.1998 11.8786L15.7349 11.8943L15.8568 11.9787C15.9225 12.0256 16.0132 12.1163 16.0569 12.1819C16.1226 12.2788 16.1351 12.332 16.1351 12.5352C16.1351 12.7384 16.1226 12.7916 16.0569 12.8885C16.0132 12.9542 15.9225 13.0448 15.8568 13.0917L15.7349 13.1762L14.1998 13.1918L12.6646 13.2074L12.649 14.7426L12.6334 16.2777L12.549 16.3996C12.5021 16.4653 12.4114 16.556 12.3457 16.5997C12.2488 16.6654 12.1957 16.6779 11.9924 16.6779C11.7892 16.6779 11.7361 16.6654 11.6391 16.5997C11.5735 16.556 11.4828 16.4653 11.4359 16.3996L11.3515 16.2777L11.3359 14.7426L11.3202 13.2074L9.78509 13.1918L8.24995 13.1762L8.12802 13.0917C7.93417 12.9542 7.85288 12.8104 7.84038 12.5696C7.82787 12.3132 7.91229 12.1288 8.11239 11.9881L8.24683 11.8943L9.78196 11.8786L11.3202 11.863L11.3359 10.3248L11.3515 8.78962L11.4453 8.65517C11.586 8.45508 11.7704 8.37066 12.0268 8.38317C12.1832 8.39254 12.2613 8.41443 12.3489 8.47071Z"
                          fill="#2589FF"
                        />
                      </svg>
                    </template>
                    <div class="icon-drop">
                      <b-dropdown-item
                        href="javascript:void(0)"
                        class="text-dark"
                        v-bind:class="j == 0 ? 'active' : ''"
                        v-for="(addressObj, k) in obj.addresses"
                        :key="k"
                        >{{ addressObj.display }}
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </div>

              <div
                class="contact-res d-flex mt-1 mb-1 align-items-baseline contact-b"
              >
                <div
                  class="new-imag-s"
                  v-if="obj.phones && obj.phones.length > 0"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.31623 8.94868C8.72457 8.81257 9 8.43043 9 8V5C9 3.34315 7.65685 2 6 2H3C2.44772 2 2 2.44772 2 3C2 10.8547 9.14528 18 17 18C17.5523 18 18 17.5523 18 17V14C18 12.3431 16.6569 11 15 11H12C11.5696 11 11.1874 11.2754 11.0513 11.6838L10.3043 13.9248C8.74458 12.9124 7.37501 11.582 6.31438 10.0522L6.07516 9.69571L8.31623 8.94868ZM12.7208 13H15C15.5523 13 16 13.4477 16 14V15.9546C14.7978 15.8457 13.621 15.5439 12.503 15.0828L12.0866 14.9024L12.7208 13ZM4.04542 4H6C6.55228 4 7 4.44772 7 5V7.27924L5.09757 7.91338C4.53454 6.67278 4.16796 5.35246 4.04542 4Z"
                      fill="#3A394C"
                    />
                  </svg>

                  <span class="number-n">
                    {{ obj.phones[0].displayInternational }}</span
                  >
                </div>

                <b-button
                  v-if="obj.phones && obj.phones.length > 0"
                  v-b-tooltip.hover
                  @click="copyText(obj.phones[0].displayInternational)"
                  class="copy-button ms-3"
                  title="Copy"
                >
                  <svg
                    width="14"
                    height="17"
                    viewBox="0 0 14 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.15935 0.584531C0.61561 0.690778 0.137497 1.18139 0.0312492 1.7345C0.0124997 1.84387 0 3.67508 0 7.03749V12.1749H0.718732H1.43746V7.09687V2.01887L5.8061 2.01262L10.1716 2.00325L10.181 1.27514L10.1872 0.550157L5.74361 0.553281C3.29679 0.556406 1.23434 0.568906 1.15935 0.584531Z"
                      fill="#003172"
                    />
                    <path
                      d="M4.0403 3.50322C3.49344 3.62197 3.02783 4.11258 2.93721 4.65944C2.89658 4.91256 2.89658 15.0904 2.93721 15.3435C3.03095 15.9217 3.53407 16.4248 4.11218 16.5185C4.36842 16.5623 12.4057 16.5591 12.6276 16.5185C13.1963 16.4091 13.6713 15.9342 13.7807 15.3654C13.8244 15.1342 13.8244 4.87194 13.7807 4.64069C13.7026 4.23133 13.3901 3.80946 13.0276 3.62509C12.687 3.45322 12.7932 3.45635 8.34332 3.45947C5.0059 3.45947 4.19655 3.46885 4.0403 3.50322ZM12.3745 10.0031V15.0811H8.37457H4.37467V10.0031V4.92506H8.37457H12.3745V10.0031Z"
                      fill="#003172"
                    />
                  </svg>
                </b-button>

                <b-button
                  @click="openWhatsApp(obj.phones[0].displayInternational)"
                  v-if="
                    obj.phones &&
                      obj.phones.length > 0 &&
                      obj.phones[0].type &&
                      obj.phones[0].type == 'mobile'
                  "
                  class="copy-button ms-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:svgjs="http://svgjs.com/svgjs"
                    version="1.1"
                    width="17"
                    height="17"
                    x="0"
                    y="0"
                    viewBox="0 0 176 176"
                    style="enable-background:new 0 0 512 512"
                    xml:space="preserve"
                    class=""
                  >
                    <g>
                      <g
                        xmlns="http://www.w3.org/2000/svg"
                        id="Layer_2"
                        data-name="Layer 2"
                      >
                        <g id="_09.whatsapp" data-name="09.whatsapp">
                          <circle
                            id="background"
                            cx="88"
                            cy="88"
                            fill="#003172"
                            r="88"
                            data-original="#29a71a"
                            class=""
                          />
                          <g id="icon" fill="#fff">
                            <path
                              d="m126.8 49.2a54.57 54.57 0 0 0 -87.42 63.13l-5.79 28.11a2.08 2.08 0 0 0 .33 1.63 2.11 2.11 0 0 0 2.24.87l27.55-6.53a54.56 54.56 0 0 0 63.09-87.21zm-8.59 68.56a42.74 42.74 0 0 1 -49.22 8l-3.84-1.9-16.89 4 .05-.21 3.5-17-1.88-3.71a42.72 42.72 0 0 1 7.86-49.59 42.73 42.73 0 0 1 60.42 0 2.28 2.28 0 0 0 .22.22 42.72 42.72 0 0 1 -.22 60.19z"
                              fill="#ffffff"
                              data-original="#ffffff"
                              class=""
                            />
                            <path
                              d="m116.71 105.29c-2.07 3.26-5.34 7.25-9.45 8.24-7.2 1.74-18.25.06-32-12.76l-.17-.15c-12.09-11.21-15.23-20.54-14.47-27.94.42-4.2 3.92-8 6.87-10.48a3.93 3.93 0 0 1 6.15 1.41l4.45 10a3.91 3.91 0 0 1 -.49 4l-2.25 2.92a3.87 3.87 0 0 0 -.35 4.32c1.26 2.21 4.28 5.46 7.63 8.47 3.76 3.4 7.93 6.51 10.57 7.57a3.82 3.82 0 0 0 4.19-.88l2.61-2.63a4 4 0 0 1 3.9-1l10.57 3a4 4 0 0 1 2.24 5.91z"
                              fill="#ffffff"
                              data-original="#ffffff"
                              class=""
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </b-button>

                <div v-if="obj.phones && obj.phones.length > 1">
                  <b-dropdown
                    size="lg"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.3984 4.55315C10.2134 4.6657 9.33174 4.91582 8.33437 5.42233C7.15253 6.02575 6.09264 6.97622 5.31725 8.12679C4.99209 8.61453 4.59189 9.43681 4.39805 10.0183C4.24485 10.4873 4.11978 11.022 4.04475 11.5504C3.99159 11.9193 3.99159 13.1512 4.04475 13.5201C4.17606 14.4424 4.41993 15.2491 4.80137 16.0213C5.22971 16.8968 5.65179 17.4845 6.34589 18.1818C7.03685 18.8696 7.63402 19.2979 8.47506 19.7106C9.06286 19.9983 9.44117 20.139 10.0383 20.2891C11.6016 20.683 13.1711 20.608 14.6969 20.0671C15.1628 19.9014 15.9694 19.498 16.3852 19.2198C17.3201 18.5976 18.1767 17.719 18.7895 16.7561C19.0147 16.4028 19.3773 15.6524 19.5243 15.2366C20.259 13.1668 20.1308 10.9469 19.1647 9.00535C18.777 8.22371 18.3424 7.60778 17.7328 6.97935C17.0574 6.279 16.3946 5.79751 15.5098 5.35979C14.5843 4.90332 13.7214 4.65632 12.6959 4.56252C12.377 4.53439 11.6548 4.52813 11.3984 4.55315ZM12.3489 8.47071C12.4114 8.51448 12.5021 8.60515 12.549 8.67081L12.6334 8.79274L12.649 10.3279L12.6646 11.863L14.1998 11.8786L15.7349 11.8943L15.8568 11.9787C15.9225 12.0256 16.0132 12.1163 16.0569 12.1819C16.1226 12.2788 16.1351 12.332 16.1351 12.5352C16.1351 12.7384 16.1226 12.7916 16.0569 12.8885C16.0132 12.9542 15.9225 13.0448 15.8568 13.0917L15.7349 13.1762L14.1998 13.1918L12.6646 13.2074L12.649 14.7426L12.6334 16.2777L12.549 16.3996C12.5021 16.4653 12.4114 16.556 12.3457 16.5997C12.2488 16.6654 12.1957 16.6779 11.9924 16.6779C11.7892 16.6779 11.7361 16.6654 11.6391 16.5997C11.5735 16.556 11.4828 16.4653 11.4359 16.3996L11.3515 16.2777L11.3359 14.7426L11.3202 13.2074L9.78509 13.1918L8.24995 13.1762L8.12802 13.0917C7.93417 12.9542 7.85288 12.8104 7.84038 12.5696C7.82787 12.3132 7.91229 12.1288 8.11239 11.9881L8.24683 11.8943L9.78196 11.8786L11.3202 11.863L11.3359 10.3248L11.3515 8.78962L11.4453 8.65517C11.586 8.45508 11.7704 8.37066 12.0268 8.38317C12.1832 8.39254 12.2613 8.41443 12.3489 8.47071Z"
                          fill="#2589FF"
                        />
                      </svg>
                    </template>
                    <div class="icon-drop">
                      <b-dropdown-item
                        href="javascript:void(0)"
                        class="text-dark"
                        v-for="(phoneObj, l) in obj.phones"
                        :key="l"
                      >
                        <div class="new-imag-s d-flex justify-content-between">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.31623 8.94868C8.72457 8.81257 9 8.43043 9 8V5C9 3.34315 7.65685 2 6 2H3C2.44772 2 2 2.44772 2 3C2 10.8547 9.14528 18 17 18C17.5523 18 18 17.5523 18 17V14C18 12.3431 16.6569 11 15 11H12C11.5696 11 11.1874 11.2754 11.0513 11.6838L10.3043 13.9248C8.74458 12.9124 7.37501 11.582 6.31438 10.0522L6.07516 9.69571L8.31623 8.94868ZM12.7208 13H15C15.5523 13 16 13.4477 16 14V15.9546C14.7978 15.8457 13.621 15.5439 12.503 15.0828L12.0866 14.9024L12.7208 13ZM4.04542 4H6C6.55228 4 7 4.44772 7 5V7.27924L5.09757 7.91338C4.53454 6.67278 4.16796 5.35246 4.04542 4Z"
                              fill="#3A394C"
                            />
                          </svg>

                          <span class="number-n">
                            {{ phoneObj.displayInternational }}</span
                          >

                          <b-button
                            v-b-tooltip.hover
                            @click="copyText(phoneObj.displayInternational)"
                            class="copy-button ms-3"
                            title="Copy"
                          >
                            <svg
                              width="14"
                              height="17"
                              viewBox="0 0 14 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.15935 0.584531C0.61561 0.690778 0.137497 1.18139 0.0312492 1.7345C0.0124997 1.84387 0 3.67508 0 7.03749V12.1749H0.718732H1.43746V7.09687V2.01887L5.8061 2.01262L10.1716 2.00325L10.181 1.27514L10.1872 0.550157L5.74361 0.553281C3.29679 0.556406 1.23434 0.568906 1.15935 0.584531Z"
                                fill="#003172"
                              />
                              <path
                                d="M4.0403 3.50322C3.49344 3.62197 3.02783 4.11258 2.93721 4.65944C2.89658 4.91256 2.89658 15.0904 2.93721 15.3435C3.03095 15.9217 3.53407 16.4248 4.11218 16.5185C4.36842 16.5623 12.4057 16.5591 12.6276 16.5185C13.1963 16.4091 13.6713 15.9342 13.7807 15.3654C13.8244 15.1342 13.8244 4.87194 13.7807 4.64069C13.7026 4.23133 13.3901 3.80946 13.0276 3.62509C12.687 3.45322 12.7932 3.45635 8.34332 3.45947C5.0059 3.45947 4.19655 3.46885 4.0403 3.50322ZM12.3745 10.0031V15.0811H8.37457H4.37467V10.0031V4.92506H8.37457H12.3745V10.0031Z"
                                fill="#003172"
                              />
                            </svg>
                          </b-button>
                          <b-button
                            @click="openWhatsApp(phoneObj.displayInternational)"
                            v-if="
                              phoneObj &&
                                phoneObj.type &&
                                phoneObj.type == 'mobile'
                            "
                            class="copy-button ms-1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              xmlns:svgjs="http://svgjs.com/svgjs"
                              version="1.1"
                              width="17"
                              height="17"
                              x="0"
                              y="0"
                              viewBox="0 0 176 176"
                              style="enable-background:new 0 0 512 512"
                              xml:space="preserve"
                              class=""
                            >
                              <g>
                                <g
                                  xmlns="http://www.w3.org/2000/svg"
                                  id="Layer_2"
                                  data-name="Layer 2"
                                >
                                  <g id="_09.whatsapp" data-name="09.whatsapp">
                                    <circle
                                      id="background"
                                      cx="88"
                                      cy="88"
                                      fill="#003172"
                                      r="88"
                                      data-original="#29a71a"
                                      class=""
                                    />
                                    <g id="icon" fill="#fff">
                                      <path
                                        d="m126.8 49.2a54.57 54.57 0 0 0 -87.42 63.13l-5.79 28.11a2.08 2.08 0 0 0 .33 1.63 2.11 2.11 0 0 0 2.24.87l27.55-6.53a54.56 54.56 0 0 0 63.09-87.21zm-8.59 68.56a42.74 42.74 0 0 1 -49.22 8l-3.84-1.9-16.89 4 .05-.21 3.5-17-1.88-3.71a42.72 42.72 0 0 1 7.86-49.59 42.73 42.73 0 0 1 60.42 0 2.28 2.28 0 0 0 .22.22 42.72 42.72 0 0 1 -.22 60.19z"
                                        fill="#ffffff"
                                        data-original="#ffffff"
                                        class=""
                                      />
                                      <path
                                        d="m116.71 105.29c-2.07 3.26-5.34 7.25-9.45 8.24-7.2 1.74-18.25.06-32-12.76l-.17-.15c-12.09-11.21-15.23-20.54-14.47-27.94.42-4.2 3.92-8 6.87-10.48a3.93 3.93 0 0 1 6.15 1.41l4.45 10a3.91 3.91 0 0 1 -.49 4l-2.25 2.92a3.87 3.87 0 0 0 -.35 4.32c1.26 2.21 4.28 5.46 7.63 8.47 3.76 3.4 7.93 6.51 10.57 7.57a3.82 3.82 0 0 0 4.19-.88l2.61-2.63a4 4 0 0 1 3.9-1l10.57 3a4 4 0 0 1 2.24 5.91z"
                                        fill="#ffffff"
                                        data-original="#ffffff"
                                        class=""
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </b-button>
                        </div>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </div>

              <div class="d-flex align-items-baseline">
                <div>
                  <h6 v-if="obj.emails && obj.emails.length > 0">
                    {{ obj.emails[0].address }}
                  </h6>
                </div>

                <div v-if="obj.emails && obj.emails.length > 1">
                  <b-dropdown
                    size="lg"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.3984 4.55315C10.2134 4.6657 9.33174 4.91582 8.33437 5.42233C7.15253 6.02575 6.09264 6.97622 5.31725 8.12679C4.99209 8.61453 4.59189 9.43681 4.39805 10.0183C4.24485 10.4873 4.11978 11.022 4.04475 11.5504C3.99159 11.9193 3.99159 13.1512 4.04475 13.5201C4.17606 14.4424 4.41993 15.2491 4.80137 16.0213C5.22971 16.8968 5.65179 17.4845 6.34589 18.1818C7.03685 18.8696 7.63402 19.2979 8.47506 19.7106C9.06286 19.9983 9.44117 20.139 10.0383 20.2891C11.6016 20.683 13.1711 20.608 14.6969 20.0671C15.1628 19.9014 15.9694 19.498 16.3852 19.2198C17.3201 18.5976 18.1767 17.719 18.7895 16.7561C19.0147 16.4028 19.3773 15.6524 19.5243 15.2366C20.259 13.1668 20.1308 10.9469 19.1647 9.00535C18.777 8.22371 18.3424 7.60778 17.7328 6.97935C17.0574 6.279 16.3946 5.79751 15.5098 5.35979C14.5843 4.90332 13.7214 4.65632 12.6959 4.56252C12.377 4.53439 11.6548 4.52813 11.3984 4.55315ZM12.3489 8.47071C12.4114 8.51448 12.5021 8.60515 12.549 8.67081L12.6334 8.79274L12.649 10.3279L12.6646 11.863L14.1998 11.8786L15.7349 11.8943L15.8568 11.9787C15.9225 12.0256 16.0132 12.1163 16.0569 12.1819C16.1226 12.2788 16.1351 12.332 16.1351 12.5352C16.1351 12.7384 16.1226 12.7916 16.0569 12.8885C16.0132 12.9542 15.9225 13.0448 15.8568 13.0917L15.7349 13.1762L14.1998 13.1918L12.6646 13.2074L12.649 14.7426L12.6334 16.2777L12.549 16.3996C12.5021 16.4653 12.4114 16.556 12.3457 16.5997C12.2488 16.6654 12.1957 16.6779 11.9924 16.6779C11.7892 16.6779 11.7361 16.6654 11.6391 16.5997C11.5735 16.556 11.4828 16.4653 11.4359 16.3996L11.3515 16.2777L11.3359 14.7426L11.3202 13.2074L9.78509 13.1918L8.24995 13.1762L8.12802 13.0917C7.93417 12.9542 7.85288 12.8104 7.84038 12.5696C7.82787 12.3132 7.91229 12.1288 8.11239 11.9881L8.24683 11.8943L9.78196 11.8786L11.3202 11.863L11.3359 10.3248L11.3515 8.78962L11.4453 8.65517C11.586 8.45508 11.7704 8.37066 12.0268 8.38317C12.1832 8.39254 12.2613 8.41443 12.3489 8.47071Z"
                          fill="#2589FF"
                        />
                      </svg>
                    </template>
                    <div class="icon-drop">
                      <b-dropdown-item
                        href="javascript:void(0)"
                        class="text-dark"
                        v-bind:class="j == 0 ? 'active' : ''"
                        v-for="(emailObj, k) in obj.emails"
                        :key="k"
                        >{{ emailObj.address }}
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </div>

              <div class="list-card">
                <b-dropdown
                  id="dropdown-1"
                  :text="obj.selectedListName"
                  class="button-drop"
                  v-model="obj.selectedListId"
                  variant="primary"
                >
                  <div class="icon-drop">
                    <b-dropdown-item
                      class="new-list-small"
                      @click="changeSelectedItem('create', index)"
                    >
                      + New List</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-for="(list, i) in listSystems"
                      @click="onSelectDropdown(list, index)"
                      :key="i"
                      class="active"
                      >{{ list.listName }}</b-dropdown-item
                    >
                  </div>
                </b-dropdown>
                <div v-if="obj.selectedListId">
                  <b-button class="mt-3" @click="saveContact(obj)"
                    >Save Contact</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-modal
        ref="createListModel"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header-close
      >
        <template #modal-title> Create List </template>
        <div class="d-flex align-items-center text-center">
          <label for="range-1" class="mr-2">Name</label>
          <b-form-input
            id="range-1"
            v-model="listName"
            type="text"
            placeholder="Enter name"
          ></b-form-input>
        </div>

        <b-button
          class="mt-3"
          @click="createList"
          :disabled="listName && listName != '' ? false : true"
          >Create</b-button
        >
        <b-button
          variant="danger"
          class="mt-3 ml-3"
          @click="closeCreateListModel"
          >Cancel</b-button
        >
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import {
  ADVANCE_PHONE_SEARCH,
  USER_EMAIL,
  ID_TOKEN_KEY,
  GET_USER_CREDIT,
  SEARCH_CEREBRO_FRANCE_EMAIL,
  SEARCH_COUNTRY_BY_LOCATION,
  GET_USER_LIST_API,
  SAVE_CONTACTS_LIST_API,
  CREATE_LIST_API,
  ADVANCE_PHONE_SEARCH_IMAGE_URL,
  GET_WHATSAPP_PROTO,
} from "../../../store/constant/actions.type";
import { loadingService } from "../../common/services/loading.service";
import UtilsService from "../../common/services/utils.services";
import { countryList } from "../../../store/constant/intergration.type.js";
import Vue from "vue";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  name: "searchByTabs",
  data() {
    return {
      hitInfoArray: [],
      showResult: false,
      withPhone: false,
      selectedIndex: null,
      showWtsImage: false,
      imageUrl: "",
      imageIndex: null,
      displayError: null,
      // checked: true,
      // debugMode: true,
      listName: "",
      options: [],
      cerebroSearchRes: [],
      cerebroIntialRes: null,
      Deselect: {
        render: (createElement) => createElement("span", ""),
      },
      countryList: countryList,
      loggedUserCountry: null,
      formPayload: {
        email: null,
        phone: null,
        firstName: null,
        middleName: null,
        lastName: null,
        country: null,
        state: null,
        city: null,
        username: null,
        // otherCountry: null,
      },
      listSystems: [],
    };
  },
  mounted() {
    if (window.parameters && typeof window.parameters == "string") {
      var args = JSON.parse(window.parameters);
      if (args && args.firstName && args.lastName) {
        this.formPayload.firstName = args.firstName;
        this.formPayload.lastName = args.lastName;
      }
      if (args && args.country) {
        this.formPayload.country = args.country;
      }
    }
    this.getUserCardit();
    this.getUserListSystem();
  },
  methods: {
    // toogleClick() {
    //   this.checked = !this.checked;
    // },
    getWhatsAppProtocol(val) {
      try {
        this.$store
          .dispatch(GET_WHATSAPP_PROTO)
          .then((response) => {
            this.selectedProtocol = response?.data[0]?.whatapp_protocol;

            if (response?.status) {
              this.$store.dispatch("changeWhatsAppPro", this.selectedProtocol);
              val = val.replace(/ /g, "");
              val = val.replace("+", "");
              val = val.replace(new RegExp("-", "g"), "");

              console.log("selectType", response?.data[0]?.whatapp_protocol);
              if (response?.data[0]?.whatapp_protocol === "mobile") {
                window.open(
                  `https://web.whatsapp.com/send?phone=${val}&text&app_absent=0`,
                  "_blank"
                );
              } else {
                window.open(`whatsapp://send?phone=${val}`, "_blank");
              }
            }
          })
          .catch((err) => {
            if (err != undefined) {
              console.log("error", err);
            }
          });
      } catch (error) {
        console.error("error", error);
      }
    },
    isWhatsAppImageUrl(url) {
      const whatsappSubdomains = ["cdn.whatsapp.net", "pps.whatsapp.net"];

      // Check if the URL contains any of the WhatsApp subdomains
      return whatsappSubdomains.some((subdomain) => url.includes(subdomain));
    },
    getGreyClass(type) {
      if (type == "username") {
        if (this.formPayload.username) {
          return false;
        } else {
          return true;
        }
      } else if (type == "email") {
        if (this.formPayload.email) {
          return false;
        } else {
          return true;
        }
      } else if (type == "phone") {
        if (this.formPayload.phone) {
          return false;
        } else {
          return true;
        }
      } else if (type == "name") {
        if (this.formPayload.firstName && this.formPayload.lastName) {
          return false;
        } else {
          return true;
        }
      }
    },
    checkAPIBody(type) {
      debugger;
      if (type == "username") {
        if (this.formPayload.username) {
          this.formPayload.email = null;
          this.formPayload.phone = null;
          this.formPayload.firstName = null;
          this.formPayload.middleName = null;
          this.formPayload.lastName = null;
          this.formPayload.state = null;
          this.formPayload.city = null;
          if (
            this.formPayload.username.startsWith(
              "https://www.linkedin.com/sales/"
            ) ||
            this.formPayload.username.startsWith(
              "https://www.linkedin.com/talent/"
            ) ||
            this.formPayload.username.startsWith("https://linkedin.com/in/ACw")
          ) {
            this.flashMessage.info({
              message: "Please enter valid linkedIn url",
            });
            return;
          } else if (
            this.formPayload.username.startsWith("https://") ||
            this.formPayload.username.startsWith("linkedin.com") ||
            this.formPayload.username.includes("linkedin.com")
          ) {
            this.callFindEmailApi();
          } else {
            this.callAdvancePhoneSearch(null);
          }
        }
      } else if (type == "email") {
        if (this.formPayload.email) {
          this.formPayload.phone = null;
          this.formPayload.firstName = null;
          this.formPayload.middleName = null;
          this.formPayload.lastName = null;
          this.formPayload.state = null;
          this.formPayload.city = null;
          this.formPayload.username = null;
          this.callAdvancePhoneSearch(null);
        }
      } else if (type == "phone") {
        if (this.formPayload.phone) {
          this.formPayload.email = null;
          this.formPayload.firstName = null;
          this.formPayload.middleName = null;
          this.formPayload.lastName = null;
          this.formPayload.state = null;
          this.formPayload.city = null;
          this.formPayload.username = null;
          this.callAdvancePhoneSearch(null);
        }
      } else if (type == "name") {
        if (
          this.formPayload.firstName &&
          this.formPayload.lastName &&
          this.formPayload.country
        ) {
          this.formPayload.email = null;
          this.formPayload.phone = null;
          this.formPayload.username = null;
          this.callAdvancePhoneSearch(null);
        } else {
          this.flashMessage.info({
            message: "Please enter a country when you search by name",
          });
          return;
        }
      }
    },
    callFindEmailApi() {
      loadingService.setloader(true);
      let linkedInUrl = this.formPayload.username;
      if (linkedInUrl.startsWith("https://")) {
        linkedInUrl = linkedInUrl.split("https://")[1];
      }
      if (linkedInUrl.startsWith("www.")) {
        linkedInUrl = linkedInUrl.split("www.")[1];
      }
      if (countryList && countryList.length > 0) {
        countryList.forEach((element) => {
          if (element && element.alpha2) {
            let alpha2 = element.alpha2.toLowerCase() + ".";
            if (linkedInUrl.startsWith(alpha2)) {
              linkedInUrl = linkedInUrl.split(alpha2)[1];
            }
            let alpha2WithSlace = "/" + element.alpha2.toLowerCase();
            if (linkedInUrl.endsWith(alpha2WithSlace)) {
              linkedInUrl = linkedInUrl.split(alpha2WithSlace)[0];
            }
          }
        });
      }
      var lastChar = linkedInUrl.substr(-1);
      if (lastChar == "/")
        linkedInUrl = linkedInUrl.substring(0, linkedInUrl.lastIndexOf("/"));
      if (linkedInUrl.includes("?")) linkedInUrl = linkedInUrl.split("?")[0];
      if (linkedInUrl.endsWith("/en"))
        linkedInUrl = linkedInUrl.split("/en")[0];
      this.$store
        .dispatch(SEARCH_CEREBRO_FRANCE_EMAIL, linkedInUrl)
        .then(async (response) => {
          if (response) {
            this.$eventBus.$emit("callCreditMethod");
            if (response.emails && response.emails.length > 0) {
              let dataToPass = {};
              let emailArray = [];
              response.emails.forEach((element) => {
                emailArray.push({ address: element.address });
              });
              dataToPass["emails"] = emailArray;
              dataToPass["urls"] = [{ url: this.formPayload.username }];
              if (response.fullName)
                dataToPass["names"] = [{ display: response.fullName }];
              if (response.jobTitle && response.industry)
                dataToPass["jobs"] = [
                  { organization: response.industry, title: response.jobTitle },
                ];
              if (response.locationName) {
                await this.$store
                  .dispatch(SEARCH_COUNTRY_BY_LOCATION, response.locationName)
                  .then((data) => {
                    if (data && data.country) {
                      dataToPass["addresses"] = [
                        {
                          display: response.locationName,
                          country: data.country,
                        },
                      ];
                    } else {
                      dataToPass["addresses"] = [
                        { display: response.locationName },
                      ];
                    }
                  })
                  .catch((err) => {
                    dataToPass["addresses"] = [
                      { display: response.locationName },
                    ];
                    throw err;
                  });
              }
              this.callAdvancePhoneSearch(dataToPass);
            } else {
              this.callAdvancePhoneSearch(null);
            }
          } else {
            this.callAdvancePhoneSearch(null);
          }
        })
        .catch((err) => {
          this.callAdvancePhoneSearch(null);
          throw err;
        });
    },
    advancePhoneSearchImg(phone, index) {
      this.imageIndex = null;
      this.imageUrl = "";
      this.displayError = null;
      // console.log("api data before hit", index)
      // let phone =formPayload.phone
      let simplifiedNumber = phone[0].displayInternational.replace(
        /[^a-zA-Z0-9]/g,
        ""
      );
      loadingService.setloader(true);
      this.$store
        .dispatch(ADVANCE_PHONE_SEARCH_IMAGE_URL, simplifiedNumber)

        .then((response) => {
          // Handle the response here
          let info = this.isWhatsAppImageUrl(response.url);
          // console.log("info", info)
          // let foundIndex = this.findObjectByIndex(this.cerebroIntialRes, index);
          // console.log("image", response.url, index)
          if (info === true) {
            // Update the 'cerebroSearchRes' array at the specified index
            this.cerebroSearchRes[index].imageUrl = response.url;
            this.cerebroSearchRes[index].errorMessage = null;
            this.$forceUpdate();
            // console.log("info true list", this.cerebroSearchRes, index);
          } else {
            // Update the 'cerebroSearchRes' array at the specified index
            this.cerebroSearchRes[index].imageUrl = null;
            this.cerebroSearchRes[index].errorMessage = response.url;
            this.$forceUpdate();
            // console.log("info false list", this.cerebroSearchRes);
          }
          // this.imageIndex = index
        })
        .catch((error) => {
          // Handle errors here
          throw error;
        })
        .finally(() => {
          // This block will be executed regardless of success or failure
          loadingService.setloader(false);
        });
    },
    // findObjectByIndex(array, targetIndex) {
    //   if (targetIndex >= 0 && targetIndex < array.length) {
    //     return array[targetIndex];
    //   }
    //   return null; // Return null if index is out of bounds
    // },

    getUserListSystem() {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_USER_LIST_API, {
          apiKey: localStorage.getItem("$s"),
        })
        .then((response) => {
          if (
            response &&
            response.listSystems &&
            response.listSystems.length > 0
          ) {
            this.listSystems = response.listSystems;
          }

          loadingService.setloader(false);
        })
        .catch((err) => {
          if (err && err.data && err.data.code) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.data.message
                ? err.data.message
                : err.data.description
                ? err.data.description
                : "Error Occurs",
            });
          }
        });
    },
    callAdvancePhoneSearch(emailData) {
      loadingService.setloader(true);
      // if (this.checked) {
      //   this.formPayload.otherCountry = true;
      // } else {
      //   this.formPayload.otherCountry = null;
      // }
      this.$store
        .dispatch(ADVANCE_PHONE_SEARCH, this.formPayload)
        .then((response) => {
          this.$eventBus.$emit("callCreditMethod");
          if (response) {
            this.cerebroSearchRes = [];
            if (response.person && response.person.length > 0) {
              this.cerebroSearchRes = [
                ...this.cerebroSearchRes,
                ...response.person,
              ];
            }
            if (
              response.person &&
              response.person.constructor.name == "Object"
            ) {
              this.cerebroSearchRes = [
                ...this.cerebroSearchRes,
                ...[response.person],
              ];
            }

            if (
              response.possiblePersons &&
              response.possiblePersons.length > 0
            ) {
              this.cerebroSearchRes = [
                ...this.cerebroSearchRes,
                ...response.possiblePersons,
              ];
            }
            if (
              response.possiblePersons &&
              response.possiblePersons.constructor.name == "Object"
            ) {
              this.cerebroSearchRes = [
                ...this.cerebroSearchRes,
                ...[response.possiblePersons],
              ];
            }
            let tempArray = [];
            this.cerebroSearchRes.forEach((element) => {
              element.errorMessage = null;
              element.imageUrl = null;
              if (element.names && element.names.length === 0) {
                if (this.formPayload.firstName && this.formPayload.lastName) {
                  let displayName = `${this.formPayload.firstName} ${this.formPayload.lastName}`;
                  if (this.formPayload.middleName) {
                    displayName = `${this.formPayload.firstName} ${this.formPayload.middleName} ${this.formPayload.lastName}`;
                  }
                  element.names = [
                    {
                      first: this.formPayload.firstName,
                      last: this.formPayload.lastName,
                      display: displayName,
                    },
                  ];
                }
              }

              if (
                element &&
                element.phones &&
                element.phones.length > 0 &&
                element.names &&
                element.names.length > 0
              ) {
                element.orderNumber = 1;
              } else if (
                element &&
                element.phones &&
                element.phones.length > 0 &&
                element.names &&
                element.names.length == 0
              ) {
                element.orderNumber = 2;
              } else if (
                element &&
                element.phones &&
                element.phones.length == 0 &&
                element.names &&
                element.names.length > 0
              ) {
                element.orderNumber = 3;
              } else {
                element.orderNumber = 4;
              }
              element.selectedListName = "Select List";
              element.selectedListId = null;
              if (element && element.phones && element.phones.length > 0) {
                tempArray.unshift(element);
              } else if (element) {
                tempArray.push(element);
              }
            });
            tempArray.sort((a, b) => a.orderNumber - b.orderNumber);
            if (emailData && tempArray.length == 1) {
              if (tempArray[0].names.length == 0) {
                if (emailData.names && emailData.names.length > 0)
                  tempArray[0].names = emailData.names;
                if (emailData.urls && emailData.urls.length > 0)
                  tempArray[0].urls = [...tempArray[0].urls, ...emailData.urls];
                if (emailData.emails && emailData.emails.length > 0)
                  tempArray[0].emails = [
                    ...tempArray[0].emails,
                    ...emailData.emails,
                  ];
                if (emailData.jobs && emailData.jobs.length > 0)
                  tempArray[0].jobs = [...tempArray[0].jobs, ...emailData.jobs];
                if (emailData.addresses && emailData.addresses.length > 0)
                  tempArray[0].addresses = [
                    ...tempArray[0].addresses,
                    ...emailData.addresses,
                  ];
                if (tempArray[0].phones.length == 0) {
                  // this.dataByOnlyEmail = true;
                  this.withPhone = false;
                }
              }
            }
            tempArray = tempArray.filter(
              (x) => x.phones && x.phones.length > 0
            );
            this.cerebroSearchRes = tempArray;
            this.cerebroIntialRes = this.cerebroSearchRes;
            this.onChangeFilter();
            loadingService.setloader(false);
            this.showResult = !this.showResult;
          } else if (emailData) {
            let tempArray = [];
            let obj = {};
            if (emailData.names && emailData.names.length > 0)
              obj.names = emailData.names;
            if (emailData.urls && emailData.urls.length > 0)
              obj.urls = emailData.urls;
            if (emailData.emails && emailData.emails.length > 0)
              obj.emails = emailData.emails;
            if (emailData.jobs && emailData.jobs.length > 0)
              obj.jobs = emailData.jobs;
            if (emailData.addresses && emailData.addresses.length > 0)
              obj.addresses = emailData.addresses;
            obj.selectedListName = "Select List";
            obj.selectedListId = null;
            tempArray.push(obj);
            // this.dataByOnlyEmail = true;
            tempArray = tempArray.filter(
              (x) => x.phones && x.phones.length > 0
            );
            this.withPhone = false;
            this.cerebroSearchRes = tempArray;
            this.cerebroIntialRes = this.cerebroSearchRes;
            this.onChangeFilter();
            loadingService.setloader(false);
            this.showResult = !this.showResult;
          }
        })
        .catch((err) => {
          if (emailData) {
            let tempArray = [];
            let obj = {};
            if (emailData.names && emailData.names.length > 0)
              obj.names = emailData.names;
            if (emailData.urls && emailData.urls.length > 0)
              obj.urls = emailData.urls;
            if (emailData.emails && emailData.emails.length > 0)
              obj.emails = emailData.emails;
            if (emailData.jobs && emailData.jobs.length > 0)
              obj.jobs = emailData.jobs;
            if (emailData.addresses && emailData.addresses.length > 0)
              obj.addresses = emailData.addresses;
            obj.selectedListName = "Select List";
            obj.selectedListId = null;
            tempArray.push(obj);
            // this.dataByOnlyEmail = true;
            this.withPhone = false;
            tempArray = tempArray.filter(
              (x) => x.phones && x.phones.length > 0
            );
            this.cerebroSearchRes = tempArray;
            this.cerebroIntialRes = this.cerebroSearchRes;
            this.onChangeFilter();
            loadingService.setloader(false);
            this.showResult = !this.showResult;
          } else {
            if (err && err.data && err.data.code) {
              loadingService.setloader(false);
              if (err.data.message == "not allow from France") {
                this.flashMessage.info({
                  title: "Not found",
                  message:
                    "Vous n’êtes pas autorisé à faire cette recherche. Si vous pensez que c’est une erreur, contactez-nous.",
                });
              } else {
                this.flashMessage.error({
                  title: "Error",
                  message: err.data.message
                    ? err.data.message
                    : err.data.description
                    ? err.data.description
                    : "Error Occurs",
                });
              }
            }
          }
        });
    },
    getUserCardit() {
      let apiId = localStorage.getItem("$s");
      if (
        localStorage.getItem(USER_EMAIL) &&
        localStorage.getItem(USER_EMAIL) !== undefined &&
        localStorage.getItem(ID_TOKEN_KEY) &&
        localStorage.getItem(ID_TOKEN_KEY) !== undefined &&
        apiId &&
        apiId !== undefined
      ) {
        let email = localStorage.getItem(USER_EMAIL);
        let payload = {
          apiId: apiId,
          email: email,
        };
        this.$store
          .dispatch(GET_USER_CREDIT, payload)
          .then((data) => {
            if (data && data.country) {
              this.formPayload.country = data.country;
              this.loggedUserCountry = data.country;
            }
          })
          .catch((err) => {
            this.flashMessage.error({
              title: "Error Message",
              message: err.message,
            });
          });
      }
    },
    close() {
      this.cerebroSearchRes = [];
      this.cerebroIntialRes = [];
      this.formPayload;
      this.formPayload.email = null;
      this.formPayload.phone = null;
      this.formPayload.firstName = null;
      this.formPayload.middleName = null;
      this.formPayload.lastName = null;
      this.formPayload.country = null;
      this.formPayload.state = null;
      this.formPayload.city = null;
      this.formPayload.username = null;
      this.withPhone = false;
      this.debugMode = false;
      // this.dataByOnlyEmail = false;
      this.showResult = !this.showResult;
    },
    openLinkInUrl(linkdinurl) {
      window.open(linkdinurl, "_blank");
    },
    onChangeFilter() {
      if (this.withPhone) {
        this.cerebroSearchRes = this.cerebroIntialRes.filter(
          (x) =>
            x.phones &&
            x.phones.length > 0 &&
            x.phones.some((item) => item.type === "mobile")
        );
        // if (this.formPayload.country) {
        //   this.cerebroSearchRes = this.cerebroSearchRes.filter(
        //     (x) =>
        //       x.addresses &&
        //       x.addresses.length > 0 &&
        //       x.addresses.some(
        //         (item) =>
        //           item.country.toLowerCase() ===
        //           this.formPayload.country.toLowerCase()
        //       )
        //   );
        // }
      }
      //  else if (this.withPhone && !this.debugMode) {
      //   this.cerebroSearchRes = this.cerebroIntialRes.filter(
      //     (x) =>
      //       x.phones &&
      //       x.phones.length > 0 &&
      //       x.phones.some((item) => item.type === "mobile")
      //   );
      // } else if (!this.withPhone && this.debugMode) {
      //   this.cerebroSearchRes = this.cerebroIntialRes;
      //   if (this.formPayload.country) {
      //     this.cerebroSearchRes = this.cerebroIntialRes.filter(
      //       (x) =>
      //         x.addresses &&
      //         x.addresses.length > 0 &&
      //         x.addresses.some(
      //           (item) =>
      //             item.country.toLowerCase() ===
      //             this.formPayload.country.toLowerCase()
      //         )
      //     );
      //   }
      // }
      else {
        this.cerebroSearchRes = this.cerebroIntialRes;
      }
    },
    onChangeWhatsAppFilter() {
      this.cerebroSearchRes.forEach((element) => {
        if (element && element.phones && element.phones.length > 0) {
          element.phones.forEach((phone) => {
            if (phone.type === "mobile" && phone.displayInternational) {
              let val = phone.displayInternational.replace(/ /g, "");
              val = val.replace("+", "");
              val = val.replace(new RegExp("-", "g"), "");
              window.open(
                `https://web.whatsapp.com/send?phone=${val}&text&app_absent=0`,
                "_blank"
              );
            }
          });
        }
      });
    },
    copyText(val) {
      this.$clipboard(val);
    },
    copyAllContacts() {
      let numArray = [];
      this.cerebroSearchRes.forEach((element) => {
        if (element && element.phones && element.phones.length > 0) {
          for (const phoneObj of element.phones) {
            const numberVal = this.removeSpaces(phoneObj.displayInternational);
            numArray.push(numberVal);
          }
        }
      });
      let copyNumbers = numArray.join(" ; ");
      this.$clipboard(copyNumbers);
    },
    removeSpaces(val) {
      let value = val.replace(/ /g, "");
      return value;
    },
    openWhatsApp(val) {
      this.getWhatsAppProtocol(val);
      // val = val.replace(/ /g, "");
      // val = val.replace("+", "");
      // val = val.replace(new RegExp("-", "g"), "");
      // window.open(
      //   `https://web.whatsapp.com/send?phone=${val}&text&app_absent=0`,
      //   "_blank"
      // );
      // console.log("selectType", this.selectedProtocol);
      // if (this.selectedProtocol === "mobile") {
      //   window.open(
      //     `https://web.whatsapp.com/send?phone=${val}&text&app_absent=0`,
      //     "_blank"
      //   );
      // } else {
      //   window.open(`whatsapp://send?phone=${val}`, "_blank");
      // }
    },
    changeSelectedItem(val, index) {
      if (val === "create") {
        this.$refs[`createListModel`].show();
        this.selectedIndex = index;
      }
    },
    createList() {
      let payload = {
        apiId: localStorage.getItem("$s"),
        user: localStorage.getItem(USER_EMAIL),
        listName: this.listName,
      };
      loadingService.setloader(true);
      if (this.listName !== "") {
        this.$store
          .dispatch(CREATE_LIST_API, payload)
          .then((response) => {
            if (response) {
              const updatedRow = {
                ...this.cerebroSearchRes[this.selectedIndex],
                selectedListId: response.listId,
                selectedListName: response.listName,
              };
              this.cerebroSearchRes.splice(this.selectedIndex, 1, updatedRow);
              this.listSystems.push(response);
              this.$refs["createListModel"].hide();
              this.listName = "";
              this.selectedIndex = null;
            }
            loadingService.setloader(false);
          })
          .catch((err) => {
            loadingService.setloader(false);
            throw err;
          });
      } else {
        loadingService.setloader(false);
      }
    },
    closeCreateListModel() {
      this.listName = "";
      this.selectedIndex = null;
      this.$refs["createListModel"].hide();
    },
    onSelectDropdown(list, index) {
      const updatedRow = {
        ...this.cerebroSearchRes[index],
        selectedListId: list.listId,
        selectedListName: list.listName,
      };
      this.cerebroSearchRes.splice(index, 1, updatedRow);
    },
    saveContact(obj) {
      let apiId = localStorage.getItem("$s");
      let selectcontact = [];
      if (obj) {
        const allPhones = [];
        const allEmails = [];
        if (obj.phones && obj.phones.length > 0) {
          obj.phones.forEach((ele) => {
            if (ele && ele.displayInternational) {
              allPhones.push(ele.displayInternational);
            }
          });
        }
        if (obj.emails && obj.emails.length > 0) {
          obj.emails.forEach((ele) => {
            if (ele) {
              allEmails.push({ email: ele.address, status: ele.status });
            }
          });
        }
        let objData = {
          name: obj.names[0].display,
          listId: obj.selectedListId,
          firstName: obj.names[0].first,
          apiId: apiId,
          lastName: obj.names[0].last,
          location:
            obj.addresses &&
            obj.addresses.length > 0 &&
            obj.addresses[0].display
              ? obj.addresses[0].display
              : null,
          jobTitle:
            obj.jobs && obj.jobs.length > 0 && obj.jobs[0].title
              ? obj.jobs[0].title
              : null,
          company:
            obj.jobs && obj.jobs.length > 0 && obj.jobs[0].organization
              ? obj.jobs[0].organization
              : null,
          linkedInUrl:
            obj.urls && obj.urls.length > 0 && obj.urls[0].url
              ? obj.urls[0].url
              : null,
          phones:
            allPhones && allPhones.length > 0 && allPhones ? allPhones : [],
          emails:
            allEmails && allEmails.length > 0 && allEmails ? allEmails : [],
        };
        selectcontact.push(objData);
      }
      if (selectcontact && selectcontact.length > 0) {
        loadingService.setloader(true);
        this.$store
          .dispatch(SAVE_CONTACTS_LIST_API, selectcontact)
          .then((response) => {
            if (response) {
              // console.log(" response => ", response);
              obj.selectedListId;
            }
            loadingService.setloader(false);
            this.flashMessage.success({
              title: "Success",
              message: "Contacts saved",
            });
          })
          .catch((err) => {
            loadingService.setloader(false);
            throw err;
          });
      }
    },
  },
  beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
    const firstName = UtilsService.getQueryStringValue("firstName");
    const lastName = UtilsService.getQueryStringValue("lastName");
    const country = UtilsService.getQueryStringValue("country");
    if (firstName) this.formPayload.firstName = firstName;
    if (lastName) this.formPayload.lastName = lastName;
    if (country) this.formPayload.country = country.toUpperCase();
  },
  computed: {
    filteredResults() {
      return this.cerebroIntialRes;
    },
  },
};
</script>

<style scoped>
.data-email input {
  width: 100%;
  background: #f7f8fa;
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 8px;
}

.email-text {
  margin-bottom: 17px;
}

.content-advance {
  max-width: 800px;
}

.content-advance p {
  margin-bottom: 20px;
}

.bv-example-inner .form-control:focus {
  border: 1px solid #e0e0e0;
}

.sarch-btn .btn-search-grey {
  background: rgb(120 120 120);
}

.from-group {
  background: #fff;
  padding: 42px;
  margin-top: 40px;
}

.sarch-btn {
  text-align: end;
}

.sarch-btn button {
  padding: 6px 24px;
  width: 98px;
  height: 40px;
  background: #2589ff;
  border: none;
  color: #fff;
  border-radius: 8px;
}

.email-text input.form-control.email {
  position: relative;
  padding: 0px 32px;
}

.email-text .new-imag-s {
  position: absolute;
  top: 7px;
  left: 21px;
}

.email-text input.form-control {
  padding: 2px 30px;
}

.bv-example-inner {
  background: rgb(247 248 250);
  padding: 0 60px;
}

.bv-example-inner {
  padding-bottom: 40px;
  border: 1px solid #dfe1e6;
  border-radius: 8px;
}

.indeed-icon {
  cursor: pointer;
}

/* result page css */
.result-ne p {
  font-size: 32px;
  color: rgb(122 134 154);
}

.result-ne button {
  background: #2589ff;
  border-radius: 8px;
  width: 110px;
  height: 36px;
  color: #fff;
  border: none;
}

.result-name h4 {
  font-size: 16px;
  color: rgb(0 0 0);
  font-weight: 700;
  font-family: Montserrat;
}

.result-data span {
  color: #7a869a;
  font-family: Montserrat;
  font-size: 13px;
}

.nomargin {
  margin: 0 !important;
}

.address-res {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.svg-start {
  margin-left: 21px;
}

.svgs-start {
  margin-left: 24px;
}

.serch-list button {
  background: #2589ff;
  border-radius: 8px;
  width: 230px;
  height: 36px;
  color: #fff;
  border: none;
}

.button-drop button {
  background: rgb(37 137 255);
  border: none;
}

.button-drop button:hover {
  background: rgb(37 137 255);
}

.new-list-small {
  background: rgb(37 137 255);
}

::v-deep .new-list-small a {
  color: #fff;
  font-size: 17px;
  font-weight: bold;
}

.button-drop {
  background: #2589ff;
  border-radius: 8px;
  width: 100%;
  height: 36px;
  color: #fff;
  border: none;
}

.serch-list svg {
  margin-left: 6px;
}

.start-result {
  padding: 32px;
  border-radius: 11px !important;
  /* height: 87%; */
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.serch-list.section-new {
  margin-top: 57px;
}

.serch-list.sections-new button {
  width: 100%;
}

.serch-list.sections-new {
  margin-top: 100px;
}

span.number-n {
  margin-left: 10px;
}

ul.dropdown-menu.show {
  background: rgb(255 255 255);
}

.copy-button {
  background: rgb(255 255 255);
  border: none;
  padding: 0;
  margin-left: 23px;
}

.icon-drop {
  background: rgb(255 255 255);
  box-shadow: 0 0.125rem 1.25rem rgb(0 0 0 / 13%) !important;
  border-radius: 10px;
}

.fontweight {
  font-weight: 600;
}

/* result page css  */

@media only screen and (max-width: 425px) {
  /* this css for (search api demo) */

  .bv-example-row p {
    line-height: 19px;
  }

  .bv-example-inner {
    padding: 0 42px;
    padding-bottom: 40px;
  }

  .bv-example-inner .from-group {
    padding: 36px;
  }

  .sarch-btn button {
    padding: 5px 21px;
    width: 90px;
    height: 36px;
  }

  /* this css for (search api demo) */
}

.slide-enter-active,
.slide-leave-active {
  transition: height 150ms ease;
}

.slide-enter,
.slide-leave-to {
  height: 0px;
}

.country-list-box {
  position: relative;
}

.country-list-box .drop-ico {
  position: absolute;
  top: 10px;
  left: 5px;
  z-index: 9;
}

::v-deep .vs--single .vs__selected-options {
  padding-left: 20px;
}

::v-deep .vs__dropdown-toggle {
  color: rgb(122 134 154);
  background: rgb(250 250 250);
  border: 1px solid rgb(224 224 224);
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
  padding: 0 0 5px 0px;
  height: 40px;
}

::v-deep .vs--single.vs--open .vs__selected {
  position: relative;
}

::v-deep .vs__open-indicator {
  cursor: pointer;
}

::v-deep ul#vs1__listbox,
::v-deep .vs__search::placeholder {
  font-size: 12px;
}

.wrapper .main__wrap {
  background: #f7f8fa;
  padding: 42px;
  border-radius: 12px;
  border: 1px solid #dfe1e6;
}

.wrapper .main__wrap .nav-link {
  color: #9ca5b4;
}

li.nav-item a {
  background: #dfe1e6;
}

.wrapper .search-btn.btn__conatiner {
  text-align: end;
}

.wrapper .ui_content {
  background: #fff;
  padding: 40px 60px;
}

.wrapper .title h4 {
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 400;
  font-style: normal;
  line-height: 30px;
}

.wrapper .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  border-radius: 8px;
  background: #f7f8fa;
  border: 1px solid #e0e0e0;
  margin: 0 0 17px 0;
}

.wrapper .form-control {
  border-left: 0;

  border: none;
}

.wrapper .global__icon {
  padding: 8px;
}

.tabs::v-deep .nav-tabs {
  border-bottom: 0;
}

.wrapper .tabs::v-deep .nav-link {
  background-color: #f7f8fa;
  color: rgb(156 164 179);
}

.wrapper .tabs::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #2589ff;
  border: none;
}

.wrapper .tabs::v-deep .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: rgb(247 248 250);
}

.wrapper .form__input {
  display: flex;
  background: #f7f8fa;
}

.wrapper .form__input {
  display: flex;
  color: rgb(122 134 154);
  background: rgb(250 250 250);
  border: 1px solid rgb(224 224 224);
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 12px;
}

.v-select::v-deep .vs__dropdown-toggle {
  background: #fafafa;
}

.v-select::v-deep .vs__search {
  padding: 4px 34px;
  color: #7a869a;
  background: #fafafa;
}

.wrapper .form-control {
  font-size: 16px;
}

.search-btn .btn-search-grey {
  background: rgb(120 120 120);
}

.email-text .new-imag-s {
  position: absolute;
  top: 7px;
  left: 21px;
}

.from-group {
  background: #fff;
  padding: 42px;
  margin-top: 40px;
}

.result-name h4 {
  font-size: 16px;
  color: rgb(0 0 0);
  font-weight: 700;
  font-family: Montserrat;
}

.search-btn {
  text-align: end;
}

span.number-n {
  margin-left: 10px;
}

.button-drop button {
  background: rgb(37 137 255);
  border: none;
}

.button-drop button:hover {
  background: rgb(37 137 255);
}

.indeed-icon {
  cursor: pointer;
}

.result-data span {
  color: #7a869a;
  font-family: Montserrat;
  font-size: 13px;
}

.address-res {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.copy-button {
  background: rgb(255 255 255);
  border: none;
  padding: 0;
  margin-left: 23px;
}

.nomargin {
  margin: 0 !important;
}

.icon-drop {
  background: rgb(255 255 255);
  box-shadow: 0 0.125rem 1.25rem rgb(0 0 0 / 13%) !important;
  border-radius: 10px;
}

.search-btn button {
  padding: 6px 24px;
  width: 98px;
  height: 40px;
  background: #2589ff;
  border: none;
  color: #fff;
  border-radius: 8px;
}

.wts-img {
  max-width: 100%;
  object-fit: cover;
  height: 200px;
  width: 100%;
}

.error-image-msg {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.username-withimage .wts-img {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  object-fit: cover;
}
.username-withimage.error_message {
  flex-direction: column;
}
.username-withimage {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 4px;
}
.contact-b {
  padding: 10px 0;
}
.error-image-msg p {
  margin: 0;
  font-size: 14px;
  line-height: 1.2;
}
.contact-b button.dropdown-toggle {
  padding: 0px 15px;
}

.username-withimage:hover {
  background-color: #f5f7f9;
  transition: 0.7s ease-out-in;
  border-radius: 11px;
}
.name-btn {
  display: flex;
  flex-direction: column;
}
.username-withimage {
  justify-content: start;
}
.name-btn button {
  border: 0;
  padding: 0;
  background: transparent;
  text-align: left;
  color: #023175;
  font-weight: 500;
}
</style>
